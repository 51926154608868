import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import OverviewSlider from '../carousel/overview_carousel';
import Server, { DEFAULT_SERVER_URL } from '../../Server';
import Loader from '../subComponents/loader';
import { permalinkExtension } from '../../util/permalink';
// import AdSection from '../subComponents/AdSection';

class Tvshowspage extends Component {

    server = new Server();

    constructor() {
        super();
        this.pageItemDoc = this.server.pages().doc('M5B22bQGzi62GOGjWuJO');
        this.showCol = this.server.shows().where("status", "==", "published").orderBy("lastEpisodeAirdate", "desc");

        this.state = {
            fetching: true,
            fetchingPage: true,
            filterState: 'all'
        };
    }

    componentDidMount() {
        this.unsubscribeShowsCollection = this.showCol.onSnapshot(this.onShowsCollectionUpdate);
        this.unsubscribeDoc = this.pageItemDoc.onSnapshot(this.onDocUpdate);
    }
    componentWillUnmount() {
        if (this.unsubscribeShowsCollection) this.unsubscribeShowsCollection();
    }

    onDocUpdate = (snapshot) => {
        let pageDoc = snapshot.exists ? snapshot.data() : {};
        this.setState({
            fetchingPage: false,
            ...pageDoc,
        });
    };

    onShowsCollectionUpdate = (snapshot) => {
        let docSnapshotData = snapshot.docs.map((docSnapshot) => ({
            id: docSnapshot.id,
            ...docSnapshot.data()
        }));
        this.setState({
            fetching: false,
            shows: docSnapshotData
        });
    };

    render() {
        let { fetching, fetchingPage, shows, coverMedia, title } = this.state;
        if (fetching || fetchingPage) return <Loader />;

        const { topAd, topAdMedia, config, isGoogleAd = false } = this.props;
        const mediaServerURL = config.mediaServerURL() || DEFAULT_SERVER_URL;

        return (
            <main id="main" className="tv-show">
                {/* <AdSection ad={topAd} preloadedAdMedia={topAdMedia} isGoogleAd={isGoogleAd} config={config} /> */}
                <OverviewSlider slides={[coverMedia]} title={title} config={config} />
                <div className="container triple-inset">
                    <div className="row">
                        {
                            (shows && shows.length > 0) &&
                            shows.map(({ id, permalink, title, posterMedia }) => {
                                let link = permalinkExtension(permalink, 'show') || '';
                                return (
                                    <Link key={id} className="col-2 mrg-btm-half" to={{ pathname: `/show/${link}`, state: { showID: id } }} title={title}>
                                        <img src={`${mediaServerURL}${posterMedia.largeThumbnail}`} alt={posterMedia.alt} />
                                    </Link>
                                )
                            })
                        }
                    </div>
                </div>
            </main>
        );
    }
}

export default Tvshowspage;
