import { auth, firestore } from './firebase/firebase';

export const DEFAULT_SERVER_URL = 'https://backend-server-dot-telearuba-app.appspot.com';
export const BASE_URL = 'https://telearuba.aw/';

const USERS = 'users';
const AD_CAMPAIGNS = 'ads';
const CONFIGURATIONS = 'configurations';
const PAGES = 'pages';
const NEWS = 'news';
const SHOWS = 'shows';
const EPISODES = 'episodes';

export default class Server {

    defaultConfig = () => {
        return firestore.collection(CONFIGURATIONS).doc('default');
    }

    pages = () => {
        return firestore.collection(PAGES);
    }

    isSignedIn = () => {
        return (auth.currentUser != null);
    }

    signInAnonymously = () => {
        return auth.signInAnonymously();
    }

    signOut = () => {
        return auth.signOut();
    }

    onAuthStateChanged = (listener) => {
        return auth.onAuthStateChanged(listener);
    }

    currentUser = () => {
        if (!auth.currentUser) {
            throw new Error('No current user logged in. Login before using this method.');
        }
        let {uid} = auth.currentUser;
        return firestore.collection(USERS).doc(uid);
    }

    news = () => {
        return firestore.collection(NEWS);
    }

    shows = () => {
        return firestore.collection(SHOWS);
    }

    episodes = () => {
        return firestore.collection(EPISODES);
    }

    ads = () => {
        return firestore.collection(AD_CAMPAIGNS);
    }

}