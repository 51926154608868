import React, { Component } from 'react';
import { DEFAULT_SERVER_URL } from '../../Server';
export default class AdImageBlock extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetching: true,
        };
    }

    async componentDidMount() {
        let { ad } = this.props;
        if (ad && ad.mediaRef) {
            let media = await ad.mediaRef.get();
            this.setState({ media: media.data(), fetching: false });
        }
    }

    render() {
        let { fetching, media } = this.state;
        if (fetching) return null;
        return (
            <a href={this.props.ad.url} target="_blank" rel="noopener noreferrer">
                <img className="img-fluid mb-5" src={`${DEFAULT_SERVER_URL}${media.originalFile}`} alt={media.alt} />
            </a>
        )
    }
}