import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { firestore } from '../../firebase/firebase';
import dtFormat from 'dateformat';
import OverviewSlider from '../carousel/overview_carousel';
import Pagination from '../subComponents/pagination';
import { DEFAULT_SERVER_URL } from '../../Server';
import Loader from '../subComponents/loader';
import AdImageBlock from '../subComponents/AdImageBlock';
import { permalinkExtension } from '../../util/permalink';
// import AdSection from '../subComponents/AdSection';

class Newspage extends Component {
    constructor(props) {
        super();

        const page = ((props.match || {}).params || {}).page || 1;

        this.state = {
            fetching: true,
            fetchingPage: true,
            currentPage: parseInt(page),
            pageSize: 50,
            offsetPages: 5,
        };
        this.newsColPage = firestore.collection('news')
            .where("status", "==", "published")
            .orderBy("airDate", "desc")
            .limit(this.state.pageSize * this.state.currentPage);
        this.state.pageItemDoc = firestore.collection('pages').doc('iwVZfd0DddS78T0F9ugX');
    }

    componentDidMount() {
        this.unsubscribeDoc = this.state.pageItemDoc.onSnapshot(this.onDocUpdate);
        this.newsColPage.get()
            .then(querySnapshot => {
                let docs = []
                querySnapshot.forEach(doc => docs.push({ id: doc.id, data: doc.data() }))
                this.setState({ docs, fetching: false }, this.getCollection);
            })
            .catch(error => console.log("Error getting documents: ", error));
    }

    componentWillUnmount() {
        if (this.unsubscribeCollection) this.unsubscribeCollection();
        if (this.unsubscribeDoc) this.unsubscribeDoc();
    }

    getCollection() {
        this.unsubscribeCollection = firestore.collection('news').where("status", "==", "published").orderBy("airDate", "desc")
            .onSnapshot(querySnapshot => {
                let docs = []
                querySnapshot.forEach(doc => docs.push({ id: doc.id, data: doc.data() }))
                this.setState({ docs });
            });
    }

    onDocUpdate = snapshot => {
        let pageDoc = snapshot.exists ? snapshot.data() : {};
        this.setState({
            fetchingPage: false,
            ...pageDoc,
        });
    };

    goToPage = (currentPage, e) => {
        this.props.history.push(`/news/page/${currentPage}`);
        this.setState({ currentPage });
        e.preventDefault();
    }

    render() {
        let { fetching, fetchingPage, docs, coverMedia, title, pageSize, offsetPages, currentPage, ad } = this.state;

        if (fetching || fetchingPage || docs.length === 0) {
            return <Loader />;
        }
        let featured = [];
        if (currentPage === 1 && docs.length > 2) {
            featured = docs.slice(0, 2);
            docs = docs.slice(2);
        }
        let nrOfPages = Math.round(docs.length / pageSize) + 1;

        docs = docs.slice((currentPage - 1) * pageSize, (currentPage) * pageSize);
        if (ad) {
            docs.splice(Math.floor(Math.random() * 5) + 1, 0, ad);
        }
        const { config, topAd, topAdMedia, isGoogleAd = false } = this.props;
        const mediaServerURL = config.mediaServerURL() || DEFAULT_SERVER_URL;

        return (
            <main id="main">
                {/* <AdSection ad={topAd} preloadedAdMedia={topAdMedia} isGoogleAd={isGoogleAd} config={config} /> */}
                <OverviewSlider slides={[coverMedia]} title={title} config={config} />
                <div className="container triple-inset">
                    {(featured.length >= 2) &&
                        <div className="row row-eq-height">
                            <div className="col">
                                <div className="card-deck">
                                    {featured.map(({ id, data }) => {
                                        let { permalink, thumbnailImage, featuredMedia, title, airDate, summary = '', } = data;
                                        const image = thumbnailImage || featuredMedia;
                                        airDate = airDate ? airDate.toDate() : new Date();
                                        summary = summary.length > 160 ? `${summary.substr(0, 160)}...` : summary;
                                        let link = permalinkExtension(permalink || '', 'news');
                                        return (
                                            <Link key={id} className="card card-vertical" to={{ pathname: `/news/${link}`, state: { newsID: id } }} >
                                                <img
                                                    className="card-img-top"
                                                    src={`${mediaServerURL}${image.mediumThumbnail}`}
                                                    title={(image.metadata || {}).title || ''}
                                                    alt={(image.metadata || {}).alt || ''}
                                                />
                                                <div className="card-body">
                                                    <h3 className="card-title">{title || ''}</h3>
                                                    <p className="card-text">{dtFormat(airDate, 'dd mmm yyyy h:MM TT')}</p>
                                                    {summary.length > 0 && <p className="card-text">{summary}</p>}
                                                </div>
                                            </Link>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    }
                    {(docs && docs.length > 2) &&
                        <div className="row">
                            {
                                docs.map(({ id, data, isAd }, i) => {
                                    if (!data) {
                                        console.log(`data of id ${id} is undefined`);
                                        return null;
                                    }
                                    let { permalink, thumbnailImage, featuredMedia, title, airDate, summary = '', } = data;
                                    const image = thumbnailImage || featuredMedia;
                                    airDate = airDate ? airDate.toDate() : new Date();
                                    summary = summary.length > 160 ? `${summary.substr(0, 160)}...` : summary;
                                    let link = permalinkExtension(permalink || '', 'news');
                                    if (isAd) {
                                        return (
                                            <div key={id} className="col-4">
                                                <AdImageBlock id={id} ad={data} />
                                            </div>
                                        )
                                    }
                                    return (
                                        <div key={id} className="col-4 d-flex align-items-stretch">
                                            <Link className="card card-vertical" to={{ pathname: `/news/${link}`, state: { permalink } }} >
                                                <img
                                                    className="card-img-top"
                                                    src={`${mediaServerURL}${image.mediumThumbnail}`}
                                                    title={(image.metadata || {}).title || ''}
                                                    alt={(image.metadata || {}).alt || ''}
                                                />
                                                <div className="card-body">
                                                    <h3 className="card-title">{title || ''}</h3>
                                                    <p className="card-text">{dtFormat(airDate, 'dd mmm yyyy h:MM TT')}</p>
                                                    {summary.length > 0 && <p className="card-text">{summary}</p>}
                                                </div>
                                            </Link>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    }
                    <div className="row">
                        <div className="col">
                            {(nrOfPages > 2) && <Pagination currentPage={currentPage} nrOfPages={nrOfPages} offsetPages={offsetPages} onPageChange={this.goToPage} />}
                        </div>
                    </div>
                </div>
            </main >
        );
    }
}

export default Newspage;
