import React, { Component } from 'react';

class AboutStats extends Component {
  render() {
    return (
        <div className="quinary-bg-color triple-inset">
            <div className="container">
                <div className="row">
                    <div className="col txt-align-center">
                        <h2 className="default-primary-color">
                            Main title
                        </h2>
                        <p className="description">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-3">
                        <div className="card card-vertical no-bg">
                            <div className="card-body txt-align-center">
                                <h3 className="card-title">105</h3>
                                <p className="card-text">Workers</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="card card-vertical no-bg">
                            <div className="card-body txt-align-center">
                                <h3 className="card-title">105</h3>
                                <p className="card-text">Workers</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="card card-vertical no-bg">
                            <div className="card-body txt-align-center">
                                <h3 className="card-title">105</h3>
                                <p className="card-text">Workers</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="card card-vertical no-bg">
                            <div className="card-body txt-align-center">
                                <h3 className="card-title">105</h3>
                                <p className="card-text">Workers</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default AboutStats;
