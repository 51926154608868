import React, { Component } from 'react';
import OverviewSlider from '../carousel/overview_carousel';
// import AboutStats from '../subComponents/aboutStats';
import GoogleMapReact from 'google-map-react';
import { firestore } from '../../firebase/firebase';
import { DEFAULT_SERVER_URL } from '../../Server';
import Loader from '../subComponents/loader';
// import AdSection from '../subComponents/AdSection';
import ContactForm from '../subComponents/contact-form';

class Contactpage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetching: true
        }
        this.state.pageItemDoc = firestore.collection('pages').doc('7iwCmfcgW38uNXV0YuIh');
    }

    componentDidMount() {
        if (this.state.pageItemDoc) {
            this.unsubscribeDoc = this.state.pageItemDoc.onSnapshot(this.onDocUpdate);
        }
    }

    componentWillUnmount() {
        this.unsubscribeDoc();
    }

    onDocUpdate = (snapshot) => {
        let pageDoc = snapshot.exists ? snapshot.data() : {};
        this.setState({
            fetching: false,
            ...pageDoc,
        });
    };

    static defaultProps = {
        center: {
            lat: 12.472799,
            lng: -69.97550,
        },
        zoom: 15
    };

    render() {
        const MarkerComponent = () => (
            <div className="marker">
                <i className="fas fa-map-marker-alt primary-color" style={{ fontSize: '32px' }} ></i>
            </div>
        );
        let { fetching, coverMedia, featuredMedia, title } = this.state;
        if (fetching) return <Loader />;

        const { topAd, topAdMedia, config, isGoogleAd = false } = this.props;
        const mediaServerURL = config.mediaServerURL() || DEFAULT_SERVER_URL;

        return (
            <main id="main">
                {/* <AdSection ad={topAd} preloadedAdMedia={topAdMedia} isGoogleAd={isGoogleAd} config={config} /> */}
                <OverviewSlider slides={[coverMedia]} title={title} config={config} />
                <div className="container triple-inset">
                    <div className="row">
                        <div className="col-1"></div>
                        <div className="col-10 txt-align-center">
                            <p>Have a comment, complaint or compliment about Telearuba programming? Running into problem with our site or app? Our viewers are our first priority, so we want to hear from you!</p>
                        </div>
                        <div className="col-1"></div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-6">
                            <div className="sticky-top sticky-offset">
                                <img className="content-image img-fluid" src={`${mediaServerURL}${featuredMedia.mediumThumbnail}`} alt={`${featuredMedia.alt}`} />
                            </div>
                        </div>
                        <div className="col-6">
                            <ContactForm />
                        </div>
                    </div>
                </div>
                {/* <AboutStats /> */}
                <div className="container-fluid mrg-top">
                    <div className="row">
                        <div className="col txt-align-center mrg-top">
                            <h2 className="primary-color">Location Telearuba</h2>
                        </div>
                    </div>
                    <div className="row">
                        <div id="contactInfo" className="senary-bg-color col-3 pos-abs">
                            <table className="table table-borderless">
                                <tbody>
                                    <tr>
                                        <td>
                                            <i className="fas fa-map-marker-alt"></i>
                                        </td>
                                        <td>Pos Chikito 1-A</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <i className="fas fa-building"></i>
                                        </td>
                                        <td>P.O. Box 392</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <i className="fas fa-phone-volume"></i>
                                        </td>
                                        <td>+297 585 1000</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <i className="fas fa-fax"></i>
                                        </td>
                                        <td>+297 585 1111</td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <i className="fas fa-envelope"></i>
                                        </td>
                                        <td>
                                            <a href="mailto:info@telearuba.aw">info@telearuba.aw</a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div style={{ height: '550px', width: '100%' }}>
                            <GoogleMapReact
                                bootstrapURLKeys={{ key: 'AIzaSyAqeLB8Ew5LNhUNUH5nHatU13UgDot1da0' }}
                                defaultCenter={this.props.center}
                                defaultZoom={this.props.zoom}
                            >
                                <MarkerComponent lat={12.472799} lng={-69.973997} text="Telearuba" />
                            </GoogleMapReact>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default Contactpage;
