import React, { Component } from 'react';
// import GoogleAd from '../subComponents/GoogleAd';

export default class DownloadPage extends Component {
    render() {
        const {config} = this.props;
        return (
            <main id="main" style={{ minHeight: '50vh' }}>
                <div className="container triple-inset-top">
                    {/* <div className="row">
                        <div className="col-12">
                            <GoogleAd config={config} />
                        </div>
                    </div> */}

                    <div className="row justify-content-md-center align-items-center triple-inset" style={{ height: '40vh' }}>
                        <div className="col-2"></div>
                        <div className="col-8 txt-align-center">
                            <h2 className="default-primary-color">Telearuba App</h2>
                            <div className="description default-primary-color">Download the Telearuba App now on Google Play and the App Store. Stay up-to-date with instant news, shows and many more.</div>
                        </div>
                        <div className="col-2"></div>
                        <div className="col-3 txt-align-center">
                            <a href="https://play.google.com/store/apps/details?id=aw.setar.telearuba" target="_blank" rel="noopener noreferrer">
                                <img src="/images/google_play.png" alt="Download Telearuba App on Google Play" />
                            </a>
                        </div>
                        <div className="col-3 txt-align-center">
                            <a href="https://itunes.apple.com/us/app/telearuba/id1047738607" target="_blank" rel="noopener noreferrer">
                                <img src="/images/app_store.png" alt="Download Telearuba App on App Store" />
                            </a>
                        </div>
                    </div>

                    {/* <div className="row">
                        <div className="col-12">
                            <GoogleAd config={config} />
                        </div>
                    </div> */}
                </div>
            </main>
        )
    }
}