import React, { Component } from "react";
import { Link } from 'react-router-dom';
import Slider from "react-slick";
import Server, { DEFAULT_SERVER_URL } from '../../Server';
import { permalinkExtension } from '../../util/permalink';

const PostSlide = ({ featuredMedia, post, mediaServerURL=DEFAULT_SERVER_URL}) => {
    let { mediumThumbnail, alt } = featuredMedia ? featuredMedia : {};
    let { type, permalink, summary = '', title } = post;
    summary = summary.length > 160 ? `${summary.substr(0, 160)}...` : summary;
    let link = permalinkExtension(permalink || '', type);
    return (
        <div className="row align-items-center">
            <div className="col-4 offset-md-2">
                <h1 className="default-secondary-color">{title}</h1>
                {summary.length > 0 && <p className="description default-secondary-color">{summary}</p>}
                <Link className="btn btn-primary" to={{ pathname: `/${type}/${link}`, state: { permalink } }} >View more</Link>
            </div>
            <div className="col-5">
                <img className="pos-img-below" src={`${mediaServerURL}${mediumThumbnail}`} alt={alt} />
            </div>
        </div>
    )
}

const NavSlide = ({ portraitSmallThumbnail, smallThumbnail, alt, mediaServerURL=DEFAULT_SERVER_URL}) => 
    <img className="pos-img-below" src={`${mediaServerURL}${portraitSmallThumbnail || smallThumbnail}`} alt={alt} />

export default class CarouselWithNav extends Component {

    server = new Server();

    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null,
            newsCol: this.server.news().where("status", "==", "published").orderBy("airDate", "desc").limit(7),
        };
    }

    componentDidMount() {
        this.unsubscribeNesCol = this.state.newsCol.onSnapshot(this.onNewsColUpdate);
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
    }

    componentWillUnmount() {
        this.unsubscribeNesCol();
    }

    onNewsColUpdate = snapshot => {
        let docs = snapshot.docs.map(docSnapshot => ({
            id: docSnapshot.id,
            ...docSnapshot.data()
        }));

        let coverMedia = this.props.slides[0].coverMedia;
        let slides = docs.map(({ id, permalink, title, summary, featuredMedia }) => ({
            type: 'post',
            post: {
                id,
                permalink,
                title,
                summary,
                type: 'news',
            },
            featuredMedia,
            coverMedia,
        }));
        this.setState({ slides });
    }

    render() {
        let { slides, nav1, nav2 } = this.state;
        const mediaServerURL = this.props.config.mediaServerURL() || DEFAULT_SERVER_URL;
        return (
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col reset-inset">
                            <Slider asNavFor={nav2}
                                ref={slider => (this.slider1 = slider)}
                                arrows={false}
                                infinite={true}
                                slidesToShow={1}
                                slidesToScroll={1}
                                fade={false}>
                                {
                                    (slides && slides.length > 0) &&
                                    slides.map(function ({ type, ...slideProps }, i) {
                                        let { largeThumbnail, highResolutionThumbnail } = slideProps.coverMedia;
                                        return (
                                            <div key={i} className="carousel" style={{
                                                backgroundImage: `url(${mediaServerURL}${highResolutionThumbnail || largeThumbnail})`,
                                                backgroundPosition: 'center',
                                                backgroundSize: 'cover',
                                                backgroundRepeat: 'no-repeat'
                                            }}>
                                                <div className="tint"></div>
                                                {(type === "post") && <PostSlide {...slideProps} mediaServerURL={mediaServerURL} />}
                                            </div>
                                        )
                                    })
                                }
                            </Slider>
                        </div>
                    </div>
                </div>
                <div id="nav-controll-carousel" className="primary-bg-color">
                    <div className="container container-pos-nav">
                        <div className="row">
                            <div className="col">
                                <Slider asNavFor={nav1}
                                    ref={slider => (this.slider2 = slider)}
                                    slidesToShow={5}
                                    slidesToScroll={1}
                                    swipeToSlide={true}
                                    focusOnSelect={true}
                                    arrows={true}
                                    centerMode={false}>
                                    {
                                        (slides && slides.length > 0) &&
                                        slides.map(({ type, coverMedia, featuredMedia }, i) =>
                                            <div key={i} className="show-thumbnail">
                                                {(type === "post") && <NavSlide {...featuredMedia} mediaServerURL={mediaServerURL} />}
                                                {(type === "image" || type === "ad") && <NavSlide {...coverMedia} mediaServerURL={mediaServerURL} />}
                                            </div>
                                        )
                                    }
                                </Slider>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
