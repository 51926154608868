import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../aside/sidebar';
import { DEFAULT_SERVER_URL } from '../../Server';
import { permalinkExtension } from '../../util/permalink';

class ArticleSingle extends Component {
    render() {
        let { sidebarPosts, post, title, config} = this.props;
        if (!post) return null;
        let { permalink, type, featuredMedia, summary, title: pTitle } = post;
        let { largeThumbnail, alt } = featuredMedia;
        summary = summary.length > 160 ? `${summary.substr(0, 160)}...` : summary;
        let link = permalinkExtension(permalink || '', type);
        const mediaServerURL = config.mediaServerURL() || DEFAULT_SERVER_URL;

        return (
            <div className="container triple-inset">
                <div className="row">
                    <div className="col-12">
                        <h3 className="secondary-color as-h2">{title}</h3>
                    </div>
                    <div className="col-4">
                        <div className="sticky-top sticky-offset">
                            <Sidebar sidebarPosts={sidebarPosts} config={config} />
                        </div>
                    </div>
                    <div className="col-8">
                        <div>
                            <Link className="card card-single bg-dark text-white" to={{ pathname: `/${type}/${link}`, state: { permalink } }} >
                                <img src={`${mediaServerURL}${largeThumbnail}`} className="card-img" alt={alt} />
                                <div className="card-img-body primary-bg-color">
                                    <h2 className="card-title default-secondary-color">{pTitle}</h2>
                                    {summary.length > 0 && <p className="card-text default-secondary-color">{summary}</p>}
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ArticleSingle;
