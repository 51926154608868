import React, { Component } from "react";
import Slider from "react-slick";
import { DEFAULT_SERVER_URL } from '../../Server';

export default class CarouselWithoutNav extends Component {

    constructor(props) {
        super(props);
        this.state = {
            nav1: null,
            nav2: null
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2
        });
    }

    render() {
        let {data, config} = this.props;
        const mediaServerURL = config.mediaServerURL() || DEFAULT_SERVER_URL;
        return (
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col reset-inset">
                            <Slider asNavFor={this.state.nav2}
                                ref={slider => (this.slider1 = slider)}
                                arrows={false}
                                infinite={true}
                                slidesToShow={1}
                                slidesToScroll={1}
                                fade={false}>
                                <div className="carousel" style={{ backgroundImage: `url("${mediaServerURL}${data.coverMedia.originalFile}")`,
                                    backgroundPosition: 'center',
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat'}}>
                                    <div className="tint"></div>
                                    <div className="row align-items-center">
                                        <div className="col-4 offset-md-2">
                                            <h1 className="default-secondary-color">{data.title}</h1>
                                            <small className="default-secondary-color">{data.showTimesDescription}</small>
                                            <p className="description default-secondary-color mrg-top">{data.description}</p>
                                        </div>
                                        <div className="col-5">
                                            <img className="pos-img-below mob-height-half" style={{'height':'480px'}} src={`${mediaServerURL}${data.posterMedia.originalFile}`} alt={data.posterMedia.alt} />
                                        </div>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
