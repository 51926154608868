import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import dtFormat from 'dateformat';
import Server, { DEFAULT_SERVER_URL } from '../../Server';
import Loader from '../subComponents/loader';
import { permalinkExtension } from '../../util/permalink';

class ArticleDeck extends Component {

    server = new Server();

    constructor(props) {
        super(props);
        const { category } = this.props;
        this.state = {
            title: this.props.title,
            newsCol: this.server.news()
                .where("status", "==", "published")
                .where("categories", "array-contains", (category ? category.id : 'Telenoticia') )
                .orderBy("airDate", "desc")
                .limit(3),
            fetching: true,
        };
    }

    componentDidMount() {
        this.unsubscribeNesCol = this.state.newsCol.onSnapshot(this.onNewsColUpdate);
    }

    componentWillUnmount() {
        this.unsubscribeNesCol();
    }

    onNewsColUpdate = snapshot => {
        let { category } = this.props;
        let categoryPosts = snapshot.docs.map(docSnapshot => ({
            id: docSnapshot.id,
            type: 'news',
            ...docSnapshot.data()
        }));
        categoryPosts = categoryPosts.filter(doc => doc.categories.includes(category ? category.id : 'Telenoticia'));
        this.setState({ categoryPosts, fetching: false });
    }

    render() {
        let { fetching, title, categoryPosts } = this.state;
        if (fetching) return <Loader />;

        const { config } = this.props;
        const mediaServerURL = config.mediaServerURL() || DEFAULT_SERVER_URL;

        return (
            <div className="container triple-inset">
                {
                    title &&
                    <div className="row">
                        <div className="col">
                            <h2 className="secondary-color">{title}</h2>
                        </div>
                    </div>
                }
                <div className="row row-eq-height">
                    <div className="col">
                        <div className="card-deck">
                            {
                                (categoryPosts && categoryPosts.length > 0) &&
                                categoryPosts.map(({ id, type, permalink, title, airDate, summary, featuredMedia }) => {
                                    airDate = airDate ? airDate.toDate() : new Date();
                                    summary = summary.length > 160 ? `${summary.substr(0, 160)}...` : summary;
                                    let link = permalinkExtension(permalink || '', type);
                                    return (
                                        <Link key={id} className="card card-vertical" to={{ pathname: `/${type}/${link}`, state: { permalink } }} >
                                            <img className="card-img-top" src={mediaServerURL + featuredMedia.squareSmallThumbnail} alt={featuredMedia.alt} />
                                            <div className="card-body">
                                                <h3 className="card-title">{title}</h3>
                                                <p className="card-text">{dtFormat(airDate, 'dd mmm yyyy h:MM TT')}</p>
                                                {summary.length > 0 && <p className="card-text">{summary}</p>}
                                            </div>
                                        </Link>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ArticleDeck;
