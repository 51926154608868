import React, { Component } from 'react';
import { firestore } from '../../firebase/firebase';
import OverviewSlider from '../carousel/overview_carousel';
import Loader from '../subComponents/loader';
// import GoogleAd from '../subComponents/GoogleAd';
class PayPerView extends Component {
    constructor(props) {
        super(props);
        this.state = {
            collapsed: [],
            fetchingPage: true,
        };
        this.state.pageItemDoc = firestore.collection('pages').doc('Be64q0QhBq5YxVCvNLUT');
        this.defaultConfig = firestore.collection('configurations').doc('default');
    }

    componentDidMount() {
        if (this.state.pageItemDoc) {
            this.unsubscribeDoc = this.state.pageItemDoc.onSnapshot(this.onDocUpdate);
        }
        this.unsubscribeCollection = this.defaultConfig.onSnapshot(this.onConfigUpdate);
    }

    componentWillUnmount() {
        this.unsubscribeDoc();
        this.unsubscribeCollection && this.unsubscribeCollection();
    }

    onDocUpdate = (snapshot) => {
        let pageDoc = snapshot.exists ? snapshot.data() : {};
        this.setState({
            fetchingPage: false,
            ...pageDoc,
        });
    };

    onConfigUpdate = (docSnapshot) => {
        const config = docSnapshot.exists ? docSnapshot.data() : {};
        this.setState({
            ppv: config.ppv
        });
    };

    toggleAccordion = toggle => {
        let { collapsed } = this.state;
        collapsed[[toggle]] = !collapsed[[toggle]];
        this.setState({ collapsed });
    }

    render() {
        let { coverMedia, fetchingPage, title, ppv = {} } = this.state;
        if (fetchingPage) return <Loader />;

        console.log(ppv);

        const { config } = this.props;
        return (
            <main id="main">
                <OverviewSlider slides={[coverMedia]} title={title} config={config} />
                <div className="container inset">
                    {/* <div className="row inset">
                        <div className="col">
                            <GoogleAd config={config} />
                        </div>
                    </div> */}
                    <div className="row">
                        <div className="col">
                            <iframe
                                className="iframe-ppv"
                                style={{ height: "512px", width: '100%' }}
                                title={ppv.title ? ppv.title : ''}
                                webkitallowfullscreen="true"
                                // src={`https://play.webvideocore.net/popplayer.php?it=d8d0k6miti0c&is_link=1&w=720&h=405&pause=1&title=CAISO+AND+SOCA+MONARCH+2024&skin=3&repeat=&brandNW=1&start_volume=34&bg_gradient1=%23ffffff&bg_gradient2=%23e9e9e9&fullscreen=1&fs_mode=2&skinAlpha=50&colorBase=%23250864&colorIcon=%23ffffff&colorHighlight=%237f54f8&direct=false&no_ctrl=&auto_hide=1&viewers_limit=0&cc_position=bottom&cc_positionOffset=70&cc_multiplier=0.03&cc_textColor=%23ffffff&cc_textOutlineColor=%23ffffff&cc_bkgColor=%23000000&cc_bkgAlpha=0.1&image=https%3A%2F%2Fmember.streamingvideoprovider.com%2Fpanel%2Fserver%2Fclip%3Fa%3DGenerateThumbnail%26clip_id%3D7749389%26size%3Dlarge&mainBg_Color=%23ffffff&aspect_ratio=16%3A9&play_button=1&play_button_style=pulsing&sleek_player=1&stretch=&auto_play=0&auto_play_type=unMute&floating_player=none`}
                                src={ppv.url ? ppv.url : ''}
                                allowFullScreen>
                            </iframe>
                        </div>
                    </div>
                    {/* <div className="row inset">
                        <div className="col">
                            <GoogleAd config={config} />
                        </div>
                    </div> */}
                </div>
            </main>
        );
    }
}

export default PayPerView;