import React, {Component} from 'react';

export default class TextField extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value || ''
        }
        this._inputtype = (props) => <input {...props} />;
    }

    componentDidUpdate(prevProps) {
        if (prevProps.validator !== this.props.validator) {
            prevProps.validator.setComponentGetValidateValues(null);
            prevProps.validator.setComponentOnValidated(null);
            prevProps.validator.componentHasUnmounted();
            this.props.validator.setComponentGetValidateValues(this.onGetValidateValues);
            this.props.validator.setComponentOnValidated(this.onValidated);
            this.props.validator.componentHasMounted();
        }
    }

    componentDidMount() {
        if (this.props.validator) {
            this.props.validator.setComponentGetValidateValues(this.onGetValidateValues);
            this.props.validator.setComponentOnValidated(this.onValidated);
            this.props.validator.componentHasMounted();
        }
    }
    componentWillUnmount() {
        if (this.props.validator) {
            this.props.validator.setComponentGetValidateValues(null);
            this.props.validator.setComponentOnValidated(null);
            this.props.validator.componentHasUnmounted();
        }
    }

    onGetValidateValues = () => {
        let value = this.state.value ? this.state.value.trim() : this.state.value;
        return {id: this.props.id, value: value};
    }

    onValidated = (results) => {
        this.setState(results);
    }

    onChange = (event) => {
        this.setState({value: event.target.value});
        if (this.props.onChange) {
            this.props.onChange(event);
        }
    }

    onBlur = (event) => {
        if (this.props.validator) {
            this.props.validator.validate(event.target.value);
        }
    }

    render() {
        let {
            id, 
            placeholder, 
            style, 
            autoComplete=false, 
            autoCorrect=false,
            autoCapitalize=false,
            spellCheck=false,
            className=''
        } = this.props;
        let {value, error} = this.state;
        let Input = this._inputtype;
        return (
            <div className='TextField'>
                <Input 
                    id={id} 
                    className= {`${className} ${error ? 'Error': ''}`}
                    placeholder={placeholder} 
                    value={value} 
                    onChange={this.onChange.bind(this)}
                    onBlur={this.onBlur.bind(this)} 
                    style={style} 
                    autoComplete={autoComplete ? 'on' : 'off'} 
                    autoCorrect={autoCorrect ? 'on' : 'off'} 
                    autoCapitalize={autoCapitalize ? 'on' : 'off'} 
                    spellCheck={spellCheck ? 'true' : 'false'}
                />
                {error ? <span className='Error'>{error}</span> : null}
            </div>
        );
    }
}
