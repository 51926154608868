import React, { useState } from 'react';
import {
    Validator,
    TextField,
    Select,
    TextArea,
    ValueSetValidator,
    EmailValidator,
    SelectValidator,
} from '../subComponents/input';

const ContactForm = () => {

    const validator = new Validator();

    const [formData, setFormData] = useState({});
    const [registrationLoading, setRegistrationLoading] = useState(false);
    const [showFormError, setShowFormError] = useState(false);
    const [showFormSuccess, setShowFormSuccess] = useState(false);

    const onChange = (type, e) => {
        let data = { ...formData };
        data[type] = e.target.value;
        setFormData(data);
    }

    const onRegister = e => {
        const results = { validatedResults: validator.validResult(), otherResults: formData };

        let errors = results.validatedResults.map(r => r.error).filter(e => e != null);
        if (errors.length > 0) {
            console.log(`${errors.length} errors`);
            setShowFormError(true);
            setShowFormSuccess(false);
        }
        else {
            const grecaptcha = window.grecaptcha;
            if (!grecaptcha) {
                console.log(`recaptcha object not defined`);
                return;
            }
            try {
                setShowFormError(false);
                setShowFormSuccess(false);
                setRegistrationLoading(true);
                let reCaptchaAction = 'contactpage';
                grecaptcha.ready(function () {
                    grecaptcha.execute('6LcF2fUUAAAAAMaYodgVCHKBnge3COCzipGT8kIK', { action: reCaptchaAction }).then(function (token) {
                        let info = results.validatedResults.reduce((dict, entry) => {
                            dict[entry.id] = entry.value;
                            return dict;
                        }, {});
                        let allResults = { ...info, ...results.otherResults };
                        let formData = Object.keys(allResults).map((key) => {
                            return encodeURIComponent(key) + '=' + encodeURIComponent(allResults[key]);
                        }).join('&');
                        formData += `&token=${token}`;
                        formData += `&recaptchaAction=${reCaptchaAction}`;

                        fetch('https://telearuba.aw/submit-contact-form/', {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8'
                            },
                            body: formData
                        })
                            .then(response => response.json())
                            .then(result => {
                                if (result.status === 'ok') {
                                    setShowFormSuccess(true);
                                } else {
                                    setShowFormError(true);
                                }
                            })
                            .catch((error) => {
                                console.error(error);
                                setShowFormError(true);
                            });
                    });
                });
            }
            catch (error) {
                console.log('Error executing grecaptcha');
                console.log(error);
            }
            finally {
                setRegistrationLoading(false);
            }
        }
        e.preventDefault();
    }

    let sendStatus = 'initial';
    if (registrationLoading) sendStatus = 'processing';
    else if (showFormSuccess || showFormError) sendStatus = 'done';

    return (
        <form className="form mrg-btm">
            <div className="form-group">
                <label htmlFor="fullName">Full Name *</label>
                <TextField
                    id="fullName"
                    name="fullName"
                    className="form-control"
                    placeholder="Full name"
                    validator={new ValueSetValidator(validator)}
                />
            </div>
            <div className="form-group">
                <label htmlFor="phoneNumber">Phone Number</label>
                <TextField
                    id="phoneNumber"
                    name="phoneNumber"
                    className="form-control"
                    placeholder="Phone Number"
                    onChange={event => onChange('phoneNumber', event)}
                />
            </div>
            <div className="form-group">
                <label htmlFor="emailAddress">Email Address *</label>
                <TextField
                    id="emailAddress"
                    name="emailAddress"
                    className="form-control"
                    placeholder="Email Address"
                    validator={new EmailValidator(validator)}
                />
            </div>
            <div className="form-group">
                <label htmlFor="companyName">Company Name</label>
                <TextField
                    id="companyName"
                    name="companyName"
                    className="form-control"
                    placeholder="Company Name"
                    onChange={event => onChange('companyName', event)}
                />
            </div>
            <div className="form-group">
                <label htmlFor="subject">Subject *</label>
                <Select
                    id="subject"
                    className="form-control"
                    value={formData['subject']}
                    onChange={event => onChange('subject', event)}
                    validator={new SelectValidator(validator)}
                    options={[
                        { key: 'partner', value: 'partner', text: 'Partner', },
                        { key: 'programming', value: 'programming', text: 'Programming', },
                        { key: 'other', value: 'other', text: 'Other', },
                    ]}
                />
            </div>
            <div className="form-group">
                <label htmlFor="message">Message *</label>
                <TextArea
                    id="message"
                    name="message"
                    className="form-control"
                    validator={new ValueSetValidator(validator)}
                />
            </div>
            {
                showFormSuccess &&
                <div style={{ backgroundColor: 'rgba(0,49,100,0.7)', paddingLeft: '5px', marginBottom: '15px', color: '#FFF' }}>
                    Thank you. We will contact you through telephone or email.
                </div>
            }
            {
                showFormError &&
                <div style={{ backgroundColor: 'rgba(153,0,0,0.7)', paddingLeft: '5px', marginBottom: '15px', color: '#FFF' }}>
                    Unable to submit form, please try again later
                </div>
            }
            <div className="form-group">
                <button className={`btn btn-primary pull-right ${sendStatus}`} onClick={onRegister} disabled={(['processing', 'done'].includes(sendStatus))}>
                    {sendStatus === 'initial' && 'Send'}
                    {sendStatus === 'processing' && <div>Sending<span>.</span><span>.</span><span>.</span></div>}
                    {sendStatus === 'done' && 'Done!'}
                </button>
            </div>
        </form>
    )
}
export default ContactForm;