import React, { Component } from 'react';
import dtFormat from 'dateformat';
import ContentSections from '../subComponents/PostContentSections';
import Sidebar from '../aside/sidebar';
import NotFoundPage from '../../components/pages/notFoundPage';

import Server, { DEFAULT_SERVER_URL } from '../../Server';
import { findObjectTypeForPermalink } from '../../util/permalink';

import Loader from '../subComponents/loader';
import {
    BrowserView,
    MobileView,
} from "react-device-detect";
// import AdSection from '../subComponents/AdSection';

class SingleNews extends Component {

    server = new Server();

    constructor(props) {
        super(props);
        const { state = {} } = this.props.location;
        this.state = {
            loadingNews: true,
            newsID: state.newsID
        };
    }

    async componentDidMount() {
        this.loadNewsItem();
    }

    loadNewsItem = async () => {
        try {
            let newsID = this.state.newsID;
            if (!newsID) {
                const { params = {} } = this.props.match;
                const permalink = params.permalink;
                const newsObject = (await findObjectTypeForPermalink('news', permalink) || {});
                newsID = newsObject.id || 'none';
                this.setState({ newsID, news: newsObject, loading: false });
            }
            this.unsubscribeNews = this.server.news().doc(newsID).onSnapshot(this.onNewsItemUpdate);
        }
        catch (error) {
            alert(error.message);
            this.setState({ loadingNews: false, news: undefined, error });
        }
    }
    componentWillUnmount() {
        (this.unsubscribeNews || (_ => _))();
    }

    onNewsItemUpdate = doc => {
        this.setState({ loadingNews: false, news: undefined });
        let news = doc.exists ? { id: doc.id, ...doc.data() } : {};
        if (news.status === 'published') {
            this.setState({ news });
        }
    };

    onClickSideBarItem = (id, url) => {
        //Change the show, then reload;
        this.setState({ newsID: id, loadingNews: true }, () => {
            (this.unsubscribeNews || (_ => _))();
            this.loadNewsItem();
            window.history.replaceState({}, null, url);
            window.scrollTo(0, 0);
        });
    }

    render() {
        const { topAd, topAdMedia, config, isGoogleAd = false } = this.props;
        const {
            loadingNews,
            news
        } = this.state;

        if (loadingNews) return <Loader />;
        if (!news) return <NotFoundPage config={config} />
        let {
            id,
            airDate,
            categories,
            contentSections,
            featuredMedia,
            title
        } = news;
        airDate = airDate ? airDate.toDate() : new Date();


        const mediaServerURL = config.mediaServerURL() || DEFAULT_SERVER_URL;

        return (
            <main id="main" className="single">
                {/* <AdSection ad={topAd} preloadedAdMedia={topAdMedia} isGoogleAd={isGoogleAd} config={config} /> */}
                <div className="container triple-inset">
                    <div className="row">
                        <div className="col-12">
                            {(categories && categories.length > 0) &&
                                <ul className="list list-categorie">
                                    {categories.map((cat, i) =>
                                        <li key={`${i}-category-${id}`}>
                                            {cat}
                                            {/* <a href="#">Sport</a> */}
                                        </li>
                                    )}
                                </ul>
                            }
                            <h1 className="secondary-color">{title}</h1>
                            <small>{/*`By: ${by} | `*/}{dtFormat(airDate, 'dd mmm yyyy h:MM TT')}</small>
                        </div>
                    </div>
                    <div className="row double-inset">
                        <div className="col-8">
                            {
                                featuredMedia &&
                                <div className="row">
                                    <div className="col-12">
                                        {(featuredMedia.type === 'video' || featuredMedia.stream) &&
                                            <div className="embed-responsive embed-responsive-16by9">
                                                <BrowserView>
                                                    <video width="730" controls poster={`${mediaServerURL}${featuredMedia.largeThumbnail}`}>
                                                        <source src={`${mediaServerURL}${featuredMedia.originalFile}`} />
                                                    </video>
                                                </BrowserView>
                                                <MobileView>
                                                    <video width="400" controls poster={`${mediaServerURL}${featuredMedia.largeThumbnail}`}>
                                                        <source src={`${mediaServerURL}${featuredMedia.stream}`} type="application/x-mpegURL" />
                                                    </video>
                                                </MobileView>
                                            </div>
                                        }
                                        {
                                            featuredMedia.type === 'image' &&
                                            <img
                                                className="content-image img-fluid"
                                                src={`${mediaServerURL}${featuredMedia.largeThumbnail}`}
                                                alt={(featuredMedia.metadata || {}).alt || ''}
                                                title={(featuredMedia.metadata || {}).title || ''}
                                            />
                                        }
                                    </div>
                                </div>
                            }
                            <ContentSections contentSections={contentSections} config={config} />
                        </div>
                        <div className="col-4">
                            <div className="sticky-top sticky-offset">
                                <Sidebar
                                    title={`Latest news`}
                                    type='news'
                                    includeAd={true}
                                    currentPost={id}
                                    config={config}
                                    onClickItem={this.onClickSideBarItem}
                                />
                                {categories.length > 0 &&
                                    <Sidebar
                                        title={`Related news`}
                                        type='news'
                                        categories={categories}
                                        currentPost={id}
                                        config={config}
                                        onClickItem={this.onClickSideBarItem}
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default SingleNews;