import React, { Component } from 'react';
import ContentSections from '../subComponents/PageContentSections';
import Loader from '../subComponents/loader';
import Server from '../../Server';
// import AdSection from '../subComponents/AdSection';

class Homepage extends Component {

    server = new Server();

    constructor(props) {
        super(props);
        this.state = {
            fetching: true,
            pageItemDoc: this.server.pages().doc('AshKvEH6HPvDSVJR3Ch3'),
        };
    }

    componentDidMount() {
        let { pageItemDoc, } = this.state;
        if (pageItemDoc) {
            this.unsubscribeDoc = pageItemDoc.onSnapshot(this.onDocUpdate);
        }
    }

    componentWillUnmount() {
        this.unsubscribeDoc();
    }

    onDocUpdate = (snapshot) => {
        let pageDoc = snapshot.exists ? snapshot.data() : {};
        this.setState({
            fetching: false,
            ...pageDoc,
        });
    };

    render() {
        const { config, topAd, topAdMedia, isGoogleAd = false } = this.props;
        let { fetching, contentSections } = this.state;
        if (fetching) {
            return <Loader />;
        }
        return (
            <main id="main">
                {/* <AdSection ad={topAd} preloadedAdMedia={topAdMedia} isGoogleAd={isGoogleAd} config={config} /> */}
                <ContentSections contentSections={contentSections} config={config} />
            </main>
        );
    }
}

export default Homepage;