import firebase from 'firebase/compat/app';
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";

var configProd = {
    apiKey: "AIzaSyAjya1If1855g8hzixfgETptopoQaHumiE",
    authDomain: "telearuba-app.firebaseapp.com",
    databaseURL: "https://telearuba-app.firebaseio.com",
    projectId: "telearuba-app",
    storageBucket: "telearuba-videos",
    messagingSenderId: "902904196280",
    timestampsInSnapshots: true
};

if (!firebase.apps.length) {
    firebase.initializeApp(configProd);
}

const auth = firebase.auth();
const firestore = firebase.firestore();
//const functions = firebase.functions();

export {
    auth,
    firestore,
    //functions
}