import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
        <footer id="footer">
            <div className="container inset">
                <div className="row">
                    <div className="col">
                        <p className="default-primary-color align-left">
                            <a href="/sitemap.txt" target="_blank" rel="noopener noreferrer">Sitemap</a>
                        </p>
                    </div>
                    <div className="col">
                        <p className="default-primary-color">&copy; {`Copyright ${new Date().getFullYear()} Telearuba`}</p>
                    </div>
                    <div className="col">
                        <p className="default-primary-color align-right">
                            By: <a href="https://www.CR38TE.com" target="_blank" rel="noopener noreferrer" className="tertiary-color">CR38TE</a>
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    );
  }
}

export default Footer;
