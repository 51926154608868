import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Sidebar from '../aside/sidebar';
import { DEFAULT_SERVER_URL } from '../../Server';
import dtFormat from 'dateformat';
import { permalinkExtension } from '../../util/permalink';

class ArticleCompact extends Component {
    render() {
        let { title, sidebarTitle, featuredPost, sidebarPosts, config} = this.props;
        if (!featuredPost) return null;
        let { title: fpTitle, airDate, summary, type, permalink } = featuredPost;
        let { mediumThumbnail, alt } = featuredPost.featuredMedia;
        airDate = airDate ? airDate.toDate() : new Date();
        summary = summary.length > 160 ? `${summary.substr(0, 160)}...` : summary;
        let link = permalinkExtension(permalink || '', type);

        const mediaServerURL = config.mediaServerURL() || DEFAULT_SERVER_URL;

        return (
            <div className="container triple-inset">
                <div className="row">
                    <div className="col-8">
                        <h2 className="secondary-color">{title}</h2>
                        <Link className="card card-compact" to={{ pathname: `/${type}/${link}`, state: { permalink } }} >
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-5">
                                        <h2 className="card-title">{fpTitle}</h2>
                                        <p className="card-text">{dtFormat(airDate, 'dd mmm yyyy h:MM TT')}</p>
                                        {summary.length > 0 && <p>{summary}</p>}
                                    </div>
                                    <div className="col-7">
                                        <img src={mediaServerURL + mediumThumbnail} alt={alt} />
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                    <div className="col-4">
                        <div className="sticky-top sticky-offset">
                            <h3 className="secondary-color as-h2">{sidebarTitle}</h3>
                            {(sidebarPosts && sidebarPosts.length > 0) && <Sidebar sidebarPosts={sidebarPosts} config={config} />}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ArticleCompact;
