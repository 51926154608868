import React, {Component} from 'react';

export default class CheckBoxField extends Component {
    
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value || false
        }
    }

    componentDidUpdate(prevProps) {
        if (prevProps.validator !== this.props.validator) {
            prevProps.validator.setComponentGetValidateValues(null);
            prevProps.validator.setComponentOnValidated(null);
            prevProps.validator.componentHasUnmounted();
            this.props.validator.setComponentGetValidateValues(this.onGetValidateValues);
            this.props.validator.setComponentOnValidated(this.onValidated);
            this.props.validator.componentHasMounted();
        }
    }

    componentDidMount() {
        if (this.props.validator) {
            this.props.validator.setComponentGetValidateValues(this.onGetValidateValues);
            this.props.validator.setComponentOnValidated(this.onValidated);
            this.props.validator.componentHasMounted();
        }
    }
    componentWillUnmount() {
        if (this.props.validator) {
            this.props.validator.setComponentGetValidateValues(null);
            this.props.validator.setComponentOnValidated(null);
            this.props.validator.componentHasUnmounted();
        }
    }

    onGetValidateValues = () => {
        let value = this.state.value ? this.state.value : this.state.value;
        return {id: this.props.id, value: value};
    }

    onValidated = (results) => {
        this.setState(results);
    }

    onChange = (event) => {
        this.setState({value: event.target.checked});
        if (this.props.onChange) {
            this.props.onChange(event);
        }
    }

    onBlur = (event) => {
        if (this.props.validator) {
            this.props.validator.validate(event.target.checked);
        }
    }

    render() {
        let {
            id, 
            name,
            style, 
            type,
            ...OtherProps
        } = this.props;
        let {value} = this.state;
        return (
            <div className='CheckBoxField'>
                <input 
                    id={id} 
                    name={name}
                    className= {'form-check-input'}
                    value={value} 
                    onChange={this.onChange.bind(this)}
                    onBlur={this.onBlur.bind(this)} 
                    style={style} 
                    type={'checkbox'}
                    {...OtherProps}
                />                
            </div>
        );
    }
}
