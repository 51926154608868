import React, { Component } from 'react';
import { firestore } from '../../firebase/firebase';
import dtFormat from 'dateformat';
import { DEFAULT_SERVER_URL } from '../../Server';
import SidebarAd from './SidebarAd';
import { permalinkExtension } from '../../util/permalink';

class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            postType: this.props.type || 'news',
            categories: this.props.categories,
            fetchting: true,
        }

        let query = firestore.collection(this.state.postType).where("status", "==", "published");
        const { categories } = this.props;
        if (categories && categories.length) {
            query = query.where("categories", "array-contains", categories[0]);
        }
        this.postCol = query.orderBy("airDate", "desc").limit(4);
    }

    componentDidMount() {
        this.unsubscribePostCol = this.postCol.onSnapshot(this.onPostColUpdate)
    }

    componentWillUnmount() {
        if (this.unsubscribePostCol) this.unsubscribePostCol();
    }

    onPostColUpdate = snapshot => {
        let { postType } = this.state;
        let sidebarPosts = snapshot.docs.map(docSnapshot => ({
            id: docSnapshot.id,
            type: postType,
            ...docSnapshot.data()
        }));

        //Remove the current post and reduce to three items. 
        const { currentPost } = this.props;
        sidebarPosts = sidebarPosts.filter(item => item.id !== currentPost).slice(0, 3);
        this.setState({ sidebarPosts });
    }

    render() {
        let { ad, sidebarPosts } = this.state;
        let { includeAd, config, onClickItem, isGoogleAd = false } = this.props;
        if (!sidebarPosts || sidebarPosts.length === 0) return null;

        const mediaServerURL = config.mediaServerURL() || DEFAULT_SERVER_URL;
        return (
            <aside>
                <div className="mrg-btm-half">
                    {(ad && includeAd) && <SidebarAd ad={ad} isGoogleAd={isGoogleAd} config={config} />}
                </div>
                <h3 className="secondary-color as-h2">{this.props.title}</h3>
                {sidebarPosts.map(({ id, permalink, type, featuredMedia, title, airDate }, i) => {
                    airDate = airDate ? airDate.toDate() : new Date();
                    let link = permalinkExtension(permalink || '', type);
                    const url = `/${type}/${link}`;
                    return (
                        <a key={`${i}-${id}`} className="card card-sidebar" href={url} onClick={e => {
                            e.preventDefault();
                            onClickItem(id, url);
                        }} >
                            <div className="card-body">
                                {/* Rudy TODO: create img class width = 100 heigth = 100 */}
                                <img src={mediaServerURL + (featuredMedia.squareSmallThumbnail || featuredMedia.tinyThumbnail)} alt={featuredMedia.alt} width="100" height="100" />
                                <h5 className="card-title">{title}</h5>
                                <p className="card-text">{dtFormat(airDate, 'dd mmm yyyy h:MM TT')}</p>
                            </div>
                        </a>
                    )
                })}
            </aside>
        );
    }
}

export default Sidebar;
