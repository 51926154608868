export default class Config {

    constructor(config) {
        this.config = config || {};
        this.configComponents = []
    }

    selectedTheme = () => {
        let themeIndex = this.config.selectedTheme || 0;
        let themes = this.config.themes || [];
        if (themes.length > themeIndex) {
            return themes[themeIndex];
        }
        return {};
    }

    liveStreamChannel = (channelName) => {
        if (this.config && this.config.liveStream && this.config.liveStream.channels) {
            return this.config.liveStream.channels[channelName];
        }
        return {};
    }

    ads = () => {
        if (this.config && this.config.ads) {
            return this.config.ads;
        }
        return {};
    }

    app = () => {
        if (this.config && this.config.app) {
            return this.config.app;
        }
        return {};
    }

    updateConfig(config) {
        this.config = config || {};
        this.configComponents.forEach( c => {
            if (c.onConfigUpdate) {
                c.onConfigUpdate(this);
            }
        });
    }

    serializableObject() {
        return this.config;
    }

    attachConfigComponent = (c) => {
        if (c) this.configComponents.push(c);
    }

    detachConfigComponent = (c) => {
        if (c) {
            this.configComponents = this.configComponents.filter( a_c => a_c !== c );
        }
    }

    available = () => {
        return (this.config != null);
    }

    serverURL = () => {
        return this.config.serverURL || null;
    }

    mediaServerURL = () => {
        return this.config.mediaServerURL || null;
    }

    aboutPageID = () => {
        return this.config.aboutPageID || null;
    }

    shareAppInfo = () => {
        return this.config.shareAppInfo || null;
    }
}