import React, { Component } from 'react';
import {
    BrowserRouter as Router,
    Route,
    Switch,
} from 'react-router-dom';
import Server from './Server';
import Config from './util/Config';

import ScrollToTop from 'react-router-scroll-top';
import Loader from './components/subComponents/loader';

// COMPONENTS
import Header from './components/header/header';
import Homepage from './components/pages/homepage';
import Newspage from './components/pages/newspage';
import SingleNews from './components/pages/singleNews';
import Watchlivepage from './components/pages/watchlivepage';
import Tvshowspage from './components/pages/tvshowspage';
import SingleTVShow from './components/pages/singleTvShow';
import Schedulepage from './components/pages/schedulepage';
import Aboutpage from './components/pages/aboutpage';
import Contactpage from './components/pages/contactpage';
import Aside from './components/aside/aside';
import Footer from './components/footer/footer';
import StickyFooterMobileDownload from './components/footer/stickyFooterMobileDownload';
import NotFoundPage from './components/pages/notFoundPage';
import DownloadPage from './components/pages/download';
import PayPerView from './components/pages/payperview';
// import FootballChallengePage from './components/pages/football-challenge-page';

// INCLUDES
import './assets/css/app.min.css';

const PATH_COMPONENTS = [
    { path: "/", Component: Homepage },
    { path: "/news/page/:page", Component: Newspage },
    { path: "/news", Component: Newspage },
    { path: "/news/:permalink", Component: SingleNews },
    { path: "/watchlive", Component: Watchlivepage },
    { path: "/shows", Component: Tvshowspage },
    { path: "/show/:permalink", Component: SingleTVShow },
    { path: "/schedule", Component: Schedulepage },
    { path: "/about", Component: Aboutpage },
    { path: "/contact", Component: Contactpage },
    { path: "/download", Component: DownloadPage },
    { path: "/ppv", Component: PayPerView },
    // { path: "/football-challenge", Component: FootballChallengePage },
]

class App extends Component {

    server = new Server();

    constructor(props) {
        super(props);
        if (!this.server.isSignedIn()) {
            this.server.signInAnonymously();
        }
        (this.unsubscribeAuthState || (_ => _))();
        this.unsubscribeAuthState = this.server.onAuthStateChanged(this.onAuthStateChanged);

        this.state = {
            loadingConfig: true,
            loadingUser: true,
            loadingTopAd: true,
        };

        this.setup();
    }

    setup = async () => {
        (this.unsubscribeConfig || (_ => _))();
        (this.unsubscribeAuthState || (_ => _))();
        this.unsubscribeConfig = this.server.defaultConfig().onSnapshot(this.onConfig, this.onConfigError)
        this.unsubscribeAuthState = this.server.onAuthStateChanged(this.onAuthStateChanged);
    }

    onAuthStateChanged = (user) => {
        if (user) {
            this.setState({
                isAnonymous: user.isAnonymous,
                uid: user.uid,
                loadingUser: false,
            });
        }
    }

    onConfig = (snapshot) => {
        let data = snapshot.exists ? snapshot.data() : {};
        let { serverURL, ...rest } = data;
        let config = new Config({ serverURL, ...rest });
        this.setState({ config, loadingConfig: false }, this.loadTopAd);
    }

    //Preload the top ad for performance reasons.
    async loadTopAd() {
        let isGoogleAd = true;
        let topAdMedia = {};
        let topAdData = {};
        const { config } = this.state;
        try {
            const topAd = await this.server.ads().doc(config.ads().image.topAdID).get();
            if (topAd.exists) {
                topAdData = topAd.data();
                const current = Math.round(new Date().getTime() / 1000);
                const start = topAdData.startDate.seconds;
                const end = topAdData.endDate.seconds;
                isGoogleAd = !(current > start && current < end);
                // console.log(`s: ${start}`);
                // console.log(`c: ${current}`);
                // console.log(`e: ${end}`);
                // console.log(`g: ${isGoogleAd}`);
                topAdMedia = await topAdData.mediaRef.get();
            }
        } catch (error) {
            console.log(error.message);
        } finally {
            this.setState({ loadingTopAd: false, topAd: topAdData, topAdMedia, isGoogleAd });
        }
    }

    onConfigError = (configError) => {
        this.setState({ config: null, loadingConfig: false, configError })
    }

    render() {
        let { loadingUser, loadingConfig, loadingTopAd, config, topAd, topAdMedia, isGoogleAd = false } = this.state;
        const loading = (loadingUser || loadingConfig || loadingTopAd);

        return (
            <Router>
                <div className="App">
                    <Header config={config} />
                    <ScrollToTop />
                    {loading ?
                        <Loader />
                        :
                        <>
                            <Switch>
                                {PATH_COMPONENTS.map(({ path, Component }, i) => {
                                    return (
                                        <Route key={`route-item-${i}`} exact path={path} render={(props) =>
                                            <Component
                                                {...props}
                                                config={config}
                                                server={this.server}
                                                topAd={topAd}
                                                topAdMedia={topAdMedia}
                                                isGoogleAd={isGoogleAd}
                                            />
                                        } />
                                    )
                                })}
                                <Route render={() => <NotFoundPage config={config} />} />
                            </Switch>
                            <Aside config={config} />
                            <Footer />
                            <StickyFooterMobileDownload />
                        </>
                    }
                </div>
            </Router>
        );
    }
}

export default App;
