import firebase from 'firebase/compat/app';

export const permalinkExtension = (permalink, postType) => {
    const baseURl = new RegExp(`http(s)?://(www.)?telearuba.aw/(app/?\\?|${postType}/)`, 'g');
    return permalink.replace(baseURl, '');
}

export const possiblePermalinksFromExtension = (permalinkExtension) => {
    const protocolOptions = ['https://', 'http://'];
    const domainOptions = ['telearuba.aw', 'www.telearuba.aw'];
    const pathOptions = ['/app/?', '/news/']
    
    return protocolOptions.reduce( (options, protocol) => {
        domainOptions.forEach( domain => 
            pathOptions.forEach( path => 
                options.push(`${protocol}${domain}${path}${permalinkExtension}`)
            )
        )
        return options;
    }, [])
}

export const findObjectTypeForPermalink = async (objectType, permalink) => {
    let possiblePermalinks = possiblePermalinksFromExtension(permalink);
    let results = await Promise.all(
        possiblePermalinks.map(
            p => firebase.firestore().collection(objectType).where('permalink', '==', p).limit(1).get()
        )
    );
    let foundItems = results.reduce((c, r) => {
        if (r.size >= 1) c.push(r.docs[0]);
        return c;
    }, []);
    if (foundItems.length >= 1) {
        return foundItems[0];
    }
    else {
        return null;
    }
}