import React, { Component } from 'react';
import NavSlider from '../carousel/carousel_with_nav';
import ArticleCompact from './articleCompact';
import ArticleDeck from '../subComponents/articleDeck';
import AboutStats from '../subComponents/aboutStats';
import ArticleSingle from '../subComponents/articleSingle';
import ContentWithSidebar from '../subComponents/ContentWithSidebar';
// import AdSection from './AdSection';
// import GoogleAd from './GoogleAd';

export default class PageContentSections extends Component {
    render() {
        let { contentSections, config} = this.props;

        if (contentSections && contentSections.length === 0) {
            return null;
        }
        return (
            <div>
                {
                    contentSections.map( ({ type, ...props }, i) => {
                        if (type === "navSlider") 
                            return <NavSlider key={i} id={i} slides={props.slides} config={config} />
                        else if (type === "featuredPosts") 
                            return <ArticleCompact key={i} id={i} {...props} config={config} />
                        else if (type === "categorySection") 
                            return <ArticleDeck key={i} id={i} {...props} config={config} />
                        else if (type === "statsSection") 
                            return <AboutStats key={i} id={i} {...props} />
                        else if (type === "singlePost") 
                            return <ArticleSingle key={i} id={i} {...props} config={config} />
                        else if (type === "contentSectionWithSidebar") 
                            return <ContentWithSidebar key={i} id={i} {...props} config={config} />
                        // else if (type === "adSection") 
                            // return <AdSection key={i} id={i} {...props} isGoogleAd={true} config={config} />
                        // else if (type === "googleAd") 
                            // return <GoogleAd key={i} id={i} config={config} />
                        else return null;
                    })
                }
            </div>
        )
    }
}