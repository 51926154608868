import React, { Component } from 'react';

class Pagination extends Component {

    renderPageButtons() {
        let { currentPage, nrOfPages, offsetPages, onPageChange } = this.props;
        let li = [];

        //Min offset = 3
        offsetPages =  Math.max(offsetPages, 3);

        // Calc center offset page
        let center = Math.round(offsetPages / 2);

        // Determine the start page in pagination
        // Max start = nrOfPages - offsetPages
        // Min start = 1
        let start = Math.max(Math.min(currentPage - center + 1, nrOfPages - offsetPages) , 1);

        // Determine the end page in pagination
        // Max end = nrOfPages
        let end = Math.min(start + offsetPages, nrOfPages);

        //Dirty hack but it works
        if(currentPage > nrOfPages - center ){
            start++; end++;
        }

        let disabled = currentPage === 1;
        li.push(<li key="prev" className={`page-item${disabled ? ' disabled' : ''}`}><button className="page-link" onClick={(e) => onPageChange(currentPage - 1, e)} tabIndex="-1" aria-disabled={disabled}><i className="fas fa-caret-left"></i></button></li>);

        for (let i = start; i < end; i++) {
            li.push(<li key={i} className={`page-item${i === currentPage ? ' active' : ''}`}><button className="page-link" onClick={(e) => onPageChange(i, e)}>{i}</button></li>)
        }

        disabled = currentPage >= nrOfPages;
        li.push(
            <li key="next" className={`page-item${disabled ? ' disabled' : ''}`}><button className="page-link" onClick={(e) => onPageChange(currentPage + 1, e)} aria-disabled={disabled}><i className="fas fa-caret-right"></i></button></li>
        );

        return li;
    }

    render() {
        return (
            <nav className="mrg-top">
                <ul className="pagination justify-content-center">
                    {this.renderPageButtons()}
                </ul>
            </nav>
        );
    }
}

export default Pagination;
