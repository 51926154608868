import React, { Component } from 'react';
export default class GoogleAd extends Component {
    componentDidMount() {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
    }

    render() {
        const { config } = this.props;
        const ads = config.ads();
        const { googleAds={} } = (ads.image || {});
        const { info={} } = (googleAds || {});

        return (
            <ins className='adsbygoogle'
                style={{ display: "block"}}
                data-ad-client={ info.web.adUnitID }
                data-ad-slot={ info.web.adSlot }
                data-ad-format='auto' />
        );
    }
}