import React, { Component } from 'react';
import { firestore } from '../../firebase/firebase';
import OverviewSlider from '../carousel/overview_carousel';
import ContentSections from '../subComponents/PageContentSections';
import Loader from '../subComponents/loader';
// import AdSection from '../subComponents/AdSection';

class Aboutpage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching: true
        }
        this.state.pageItemDoc = firestore.collection('pages').doc('nfHYVcEoetbbQEj6DjHq');
    }

    componentDidMount() {
        if (this.state.pageItemDoc) {
            this.unsubscribeDoc = this.state.pageItemDoc.onSnapshot(this.onDocUpdate);
        }
    }

    componentWillUnmount() {
        this.unsubscribeDoc();
    }

    onDocUpdate = (snapshot) => {
        let pageDoc = snapshot.exists ? snapshot.data() : {};
        this.setState({
            fetching: false,
            ...pageDoc,
        });
    };
    render() {
        let { fetching, contentSectionsNew, coverMedia, title, isGoogleAd = false } = this.state;
        if (fetching) return <Loader />;
        const { topAd, topAdMedia, config } = this.props;

        return (
            <main id="main">
                {/* <AdSection ad={topAd} preloadedAdMedia={topAdMedia} isGoogleAd={isGoogleAd} config={config} /> */}
                <OverviewSlider slides={[coverMedia]} title={title} config={config} />
                <ContentSections contentSections={contentSectionsNew} config={config} />
            </main>
        );
    }
}

export default Aboutpage;
