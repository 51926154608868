import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { firestore } from '../../firebase/firebase';
import { DEFAULT_SERVER_URL } from '../../Server';


const ContactInfoAsideColumn = ({ title, contactInfo }) => {
    return (
        <div className="col">
            <h3 className="default-secondary-color">{title}</h3>
            {
                contactInfo.length > 0 &&
                <ul className="list">
                    {contactInfo.map(function ({ type, value }, i) {
                        if (type === 'text') return <li key={`text-${i}`}>{value}</li>;
                        if (type === 'address') return <li key={`address-${i}`}><i className="fas fa-map-marker-alt"></i> {value}</li>;
                        if (type === 'email') return <li key={`email-${i}`}><a href={`mailto:${value}`}><i className="fas fa-envelope"></i> {value}</a></li>;
                        if (type === 'phone') return <li key={`phone-${i}`}><i className="fas fa-phone-volume"></i> {value}</li>;
                        if (type === 'mobile') return <li key={`mobile-${i}`}><i className="fas fa-mobile"></i> {value}</li>;
                        if (type === 'fax') return <li key={`fax-${i}`}><i className="fas fa-fax"></i> {value}</li>;
                        if (type === 'building') return <li key={`building-${i}`}><i className="fas fa-building"></i> {value}</li>;
                        return null;
                    })}
                </ul>
            }
        </div>
    )
}

const LinksAsideColumn = ({ title, links }) => {
    return (
        <div className="col">
            <h3 className="default-secondary-color">{title}</h3>
            {
                links.length > 0 &&
                <ul className="list">
                    {links.map(({ link, text }, i) =>
                        <li key={i}>
                            <Link to={link} title={`Go to ${text}`}>{text}</Link>
                        </li>
                    )}
                </ul>
            }
        </div>
    )
}

const NewsletterAsideColumn = ({ title, description }) => {
    return (
        <div className="col">
            <h3 className="default-secondary-color">{title}</h3>
            <p className="default-secondary-color">{description}</p>
            <script type="text/javascript" src="https://js.createsend1.com/javascript/copypastesubscribeformlogic.js"></script>
            <form id="subForm" class="js-cm-form" className="form" action="https://www.createsend.com/t/subscribeerror?description=" method="post" data-id="92D4C54F0FEC16E5ADC2B1904DE9ED1AF9594973EA0C12621A365672085D04BED66540D7FCD7736AEA6F511FFC1DE9D011018756EA5D33981247D8C68F2F9982">
                <div className="form-row">
                    <div className="form-group col-md-8">
                        <input id="email" name="cm-ydydijl-ydydijl" type="text" className="form-control" placeholder="Email..." required />
                    </div>
                    <div className="form-group col-md-4">
                        <button type="submit" className="btn btn-quinary">Subscribe</button>
                    </div>
                </div>
            </form>
        </div>
    )
}

class Aside extends Component {

    constructor(props) {
        super(props);
        this.state = {
            fetching: true,
        }
        this.pageItemDoc = firestore.collection('pages').where('template', '==', 'aside');
    }

    
    componentDidMount() {
        this.unsubscribeDoc = this.pageItemDoc.onSnapshot(this.onDocUpdate);
    }

    componentWillUnmount() {
        this.unsubscribeDoc();
    }

    onDocUpdate = (snapshot) => {
        let pageDoc = {};
        snapshot.forEach(function (doc) {
            pageDoc = {
                id: doc.id,
                ...doc.data(),
            };
        });
        this.setState({ fetching: false, ...pageDoc });
    };

    render() {

        let { fetching, contentSections } = this.state
        if (fetching) return null;

        let coverMedia = contentSections[0].coverMedia;
        let columns = contentSections[1].columns;

        const mediaServerURL = this.props.config.mediaServerURL() || DEFAULT_SERVER_URL;

        return (
            <aside id="complementary">
                <div className="container-fluid triple-inset" style={{
                    backgroundImage: `url(${mediaServerURL}${coverMedia.largeThumbnail})`,
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat'
                }}>
                    <div className="row justify-content-md-center">
                        <div className="col-12 txt-align-center mrg-btm">
                            <h2 className="default-secondary-color">Telearuba App</h2>
                            <div className="description default-secondary-color">Download the Telearuba App now on Google Play and the App Store. Stay up-to-date with instant news, shows and many more.</div>
                        </div>
                        <div className="col-2 txt-align-center">
                            <a href="https://play.google.com/store/apps/details?id=aw.setar.telearuba" target="_blank" rel="noopener noreferrer">
                                <img src="/images/google_play.png" alt="Download Telearuba App on Google Play" />
                            </a>
                        </div>
                        <div className="col-2 txt-align-center">
                            <a href="https://itunes.apple.com/us/app/telearuba/id1047738607" target="_blank" rel="noopener noreferrer">
                                <img src="/images/app_store.png" alt="Download Telearuba App on App Store" />
                            </a>
                        </div>
                    </div>
                </div>
                <div className="primary-bg-color triple-inset">
                    <div className="container">
                        <div className="row">
                            {
                                columns.length > 0 && columns.map(function ({ type, ...props }, i) {
                                    if (type === 'contactInfo') return <ContactInfoAsideColumn key={i} {...props} />;
                                    if (type === 'links') return <LinksAsideColumn key={i} {...props} />;
                                    if (type === 'newsletter') return <NewsletterAsideColumn key={i} {...props} />;
                                    return null;
                                })
                            }
                        </div>
                    </div>
                </div>
            </aside>
        );
    }
}

export default Aside;
