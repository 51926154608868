import React, { Component } from 'react';
import { DEFAULT_SERVER_URL } from '../../Server';
import GoogleAd from '../subComponents/GoogleAd';

export default class SidebarAd extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching: true,
        }
    }

    async componentDidMount() {
        let { ad } = this.props;
        if (ad && ad.mediaRef) {
            let media = await ad.mediaRef.get();
            this.setState({ media: media.data(), fetching: false });
        }
    }

    render() {
        let { fetching, media } = this.state;
        let { isGoogleAd, config} = this.props;
        if (fetching && !isGoogleAd) return null;

        const mediaServerURL = config.mediaServerURL() || DEFAULT_SERVER_URL;
        return (
            <React.Fragment>
                {
                    !isGoogleAd &&
                    <a href={this.props.ad.url} target="_blank" rel="noopener noreferrer">
                        <img src={`${mediaServerURL}${media.originalFile}`} alt={media.alt} />
                    </a>
                }
                {isGoogleAd && <GoogleAd config={config} />}
            </React.Fragment>
        )
    }
}