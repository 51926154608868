import React, { Component } from 'react';
import {
    PopupboxManager,
    PopupboxContainer
} from 'react-popupbox';
import dtFormat from 'dateformat';
import NavSlider from '../carousel/carousel_without_nav';
import NotFoundPage from '../../components/pages/notFoundPage';
import Server, { DEFAULT_SERVER_URL } from '../../Server';
import { permalinkExtension, findObjectTypeForPermalink } from '../../util/permalink';
import Loader from '../subComponents/loader';
import {
    BrowserView,
    MobileView,
} from "react-device-detect";
// import AdSection from '../subComponents/AdSection';

class SingleTVShow extends Component {

    server = new Server();

    constructor(props) {
        super(props);
        const { state = {} } = this.props.location;
        this.state = {
            loadingShow: true,
            loadingEpisodes: true,
            showID: state.showID
        };

        this.showEpisodesCol = this.server.episodes().where("status", "==", "published");
    }

    componentDidMount() {
        this.loadShow();
    }

    async loadShow() {
        try {
            let showID = this.state.showID;
            if (!showID) {
                const { params = {} } = this.props.match;
                const permalink = params.permalink;
                const showObject = (await findObjectTypeForPermalink('shows', permalink) || {});
                showID = showObject.id || 'none';
                this.setState({ showID });
            }
            this.unsubscribeShow = this.server.shows().doc(showID).onSnapshot(this.onShowUpdate);
            this.unsubscribeShowEpisodes = this.showEpisodesCol.where('showID', '==', showID).orderBy("airDate", "desc").onSnapshot(this.onShowEpisodesUpdate)
            if (showID !== 'none') {
                this.getLatestEpisodes(showID);
            }
        }
        catch (error) {
            alert(error.message);
            this.setState({ loadingShow: false, show: undefined, error });
        }
    }

    componentWillUnmount() {
        (this.unsubscribeShow || (_ => _))();
        (this.unsubscribeShowEpisodes || (_ => _))();
    }

    onShowUpdate = doc => {
        this.setState({ loadingShow: false, show: undefined });
        let show = doc.exists ? { id: doc.id, ...doc.data() } : {};
        if (show.status === 'published') {
            this.setState({ show });
        }
    };

    onShowEpisodesUpdate = query => {
        var episodes = query.docs.map(d => ({ id: d.id, ...d.data() }));
        if (episodes.length > 0) {
            this.setState({
                showEpisodes: episodes,
                selectedGroup: episodes[0].groupName,
            });
        }
        this.setState({ loadingEpisodes: false });
    }

    getLatestEpisodes = async (showID) => {
        try {
            const results = await this.server.shows()
                .where("status", "==", "published")
                .orderBy("lastEpisodeAirdate", "desc")
                .limit(4).get();
            let shows = results.docs.map(s => ({ id: s.id, ...s.data() }));
            shows = shows.filter(s => s.id !== showID).slice(0, 3);
            this.setState({ sidebarShows: shows });
        }
        catch (error) {
            console.log("Error getting documents: ", error);
        }
    }

    openPopupbox = (title, media, e) => {
        console.log(media);
        const mediaServerURL = this.props.config.mediaServerURL() || DEFAULT_SERVER_URL;
        const content = (
            <div>
                <BrowserView>
                    <video key={media.refID} width="100%" id='player' controls poster={`${mediaServerURL}${media.largeThumbnail}`}>
                        <source src={`${mediaServerURL}${media.originalFile}`} />
                    </video>
                </BrowserView>
                <MobileView>
                    <video key={media.refID} width="320" id='player' poster={`${mediaServerURL}${media.largeThumbnail}`}>
                        <source src={mediaServerURL + media.stream} type="application/x-mpegURL" />
                    </video>
                </MobileView>
            </div>
        )
        PopupboxManager.open({
            content,
            config: {
                titleBar: {
                    enable: true,
                    text: title,
                    closeButtonClassName: 'close-btn'
                },
                fadeIn: true,
                fadeInSpeed: 500,
                onClosed: () => {
                    const player = document.getElementById("player");
                    if (player && player.pause) {
                        player.currentTime = 0;
                        player.load();
                    }
                }
            }
        });
    }

    onGroupSelected(selectedGroup, e) {
        this.setState({ selectedGroup })
        e.preventDefault();
    }

    render() {
        const { topAd, topAdMedia, config, isGoogleAd = false } = this.props;
        let {
            loadingShow,
            loadingEpisodes,
            show = {},
            showEpisodes,
            sidebarShows,
            selectedGroup
        } = this.state;

        if (loadingShow || loadingEpisodes) return <Loader />;
        if (!show) return <NotFoundPage config={config} />;

        var groups = [];
        if (showEpisodes) {
            groups = showEpisodes.map(ep => ep.groupName);
            groups = groups.filter((v, i) => groups.indexOf(v) === i);
            showEpisodes = showEpisodes.filter(ep => ep.groupName === selectedGroup);
        }

        const {
            title,
            showTimesDescription,
            coverMedia,
            posterMedia,
            description,
        } = show;

        const mediaServerURL = config.mediaServerURL() || DEFAULT_SERVER_URL;

        return (
            <main id="main" className="single">
                {/* <AdSection ad={topAd} preloadedAdMedia={topAdMedia} isGoogleAd={isGoogleAd} config={config} /> */}
                <NavSlider data={{ title, showTimesDescription, coverMedia, posterMedia, description }} config={config} />
                {
                    groups.length > 0 &&
                    <div className="container mrg-top">
                        <div className="row">
                            <div className='col'>
                                {groups.map((group, i) => <button key={i} onClick={(e) => this.onGroupSelected(group, e)} className={`btn btn-primary${i > 0 ? ' ml-3' : ''}`}>{group}</button>)}
                            </div>
                        </div>
                    </div>
                }
                <div className="container triple-inset">
                    <div className="row">
                        <div className="col-9">
                            <BrowserView>
                                <PopupboxContainer />
                                {
                                    (showEpisodes && showEpisodes.length > 0) &&
                                    showEpisodes.map(({ id, featuredMedia, title, seoDescription = '', airDate }) => {
                                        airDate = airDate ? airDate.toDate() : new Date();
                                        seoDescription = seoDescription.length > 160 ? `${seoDescription.substr(0, 160)}...` : seoDescription;
                                        return (
                                            <div key={id} className="card card-tv-show mb-3" onClick={(e) => this.openPopupbox(title, featuredMedia, e)}>
                                                <div className="row no-gutters">
                                                    <div className="col-md-4">
                                                        <i className="fas fa-play-circle"></i>
                                                        <img src={`${mediaServerURL}${featuredMedia.largeThumbnail}`} className="card-img" alt={featuredMedia.alt} />
                                                    </div>
                                                    <div className="col-md-8">
                                                        <div className="card-body">
                                                            <h5 className="card-title">{title} <small className="pull-right">{isNaN(featuredMedia.duration) ? '' : `${Math.round(featuredMedia.duration / 60)}m`}</small></h5>
                                                            <p className="card-text" style={{ marginBottom: '15px' }}><small className="text-muted">{dtFormat(airDate, 'dd mmm yyyy h:MM TT')}</small></p>
                                                            {seoDescription.length > 0 && <p className="card-text" style={{ marginBottom: '15px' }}>{seoDescription}</p>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </BrowserView>
                            <MobileView>
                                {
                                    (showEpisodes && showEpisodes.length > 0) &&
                                    showEpisodes.map(({ id, featuredMedia, title, seoDescription = '', airDate }) => {
                                        airDate = airDate ? airDate.toDate() : new Date();
                                        seoDescription = seoDescription.length > 160 ? `${seoDescription.substr(0, 160)}...` : seoDescription;
                                        return (
                                            <div key={id} className="card card-tv-show mb-3" onClick={(e) => this.openPopupbox(title, featuredMedia, e)}>
                                                <div className="row no-gutters">
                                                    <div className="col-md-4">
                                                        <i className="fas fa-play-circle"></i>
                                                        <div className="embed-responsive embed-responsive-16by9">
                                                            <video width="320" controls poster={`${mediaServerURL}${featuredMedia.largeThumbnail}`}>
                                                                <source src={mediaServerURL + featuredMedia.stream} type="application/x-mpegURL" />
                                                            </video>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-8">
                                                        <div className="card-body">
                                                            <h5 className="card-title">{title} <small className="pull-right">{isNaN(featuredMedia.duration) ? '' : `${Math.round(featuredMedia.duration / 60)}m`}</small></h5>
                                                            <p className="card-text" style={{ marginBottom: '15px' }}><small className="text-muted">{dtFormat(airDate, 'dd mmm yyyy h:MM TT')}</small></p>
                                                            {seoDescription.length > 0 && <p className="card-text" style={{ marginBottom: '15px' }}>{seoDescription}</p>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </MobileView>
                        </div>
                        <div className="col-1"></div>
                        <div className="col-2">
                            <div className="sticky-top sticky-offset">
                                <h3 className="primary-color">Other tv shows</h3>
                                <div className="row">
                                    {sidebarShows && sidebarShows.map(({ id, permalink, title, posterMedia }) => {
                                        let link = permalinkExtension(permalink, 'show') || '';
                                        const url = `/show/${link}`;
                                        return (
                                            <a key={id} className="col-12 mrg-btm-half" href={url} title={title} onClick={e => {
                                                e.preventDefault();
                                                //Change the show, then reload;
                                                this.setState({ showID: id, loadingNews: true, loadingEpisodes: true }, () => {
                                                    (this.unsubscribeShow || (_ => _))();
                                                    (this.unsubscribeShowEpisodes || (_ => _))();
                                                    this.loadShow();
                                                    window.history.replaceState({}, null, url);
                                                    window.scrollTo(0, 0);
                                                });
                                            }}>
                                                <img src={`${mediaServerURL}${posterMedia.largeThumbnail}`} alt={posterMedia.alt} />
                                            </a>
                                        )
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        );
    }
}

export default SingleTVShow;