import React, { Component } from "react";
import Slider from "react-slick";
import { DEFAULT_SERVER_URL } from '../../Server';

export default class CarouselOverview extends Component {
    render() {
        let { title, slides, config } = this.props;
        
        const mediaServerURL = config.mediaServerURL() || DEFAULT_SERVER_URL;

        return (
            <div>
                <div className="container-fluid">
                    <div className="row">
                        <div className="col reset-inset container-slider">
                            {
                                slides && slides.map(({refID, originalFile}) =>
                                    <Slider key={refID}
                                        ref={slider => (this.slider1 = slider)}
                                        arrows={false}
                                        infinite={false}
                                        slidesToShow={1}
                                        slidesToScroll={1}
                                        fade={false}>
                                        <div className="carousel carousel-overview" style={{
                                            backgroundImage: `url(${mediaServerURL}${originalFile}`,
                                            backgroundPosition: 'center',
                                            backgroundSize: 'cover',
                                            backgroundRepeat: 'no-repeat'
                                        }}>
                                            <div className="tint"></div>
                                            <h1 className="default-secondary-color pos-center">{title}</h1>
                                        </div>
                                    </Slider>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
