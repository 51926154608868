import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Server from '../../Server';
import Logo from '../../assets/img/telearuba_logo.svg';

class Header extends Component {

    server = new Server();
    pageItemDoc = this.server.pages().where('template', '==', 'header');

    constructor(props) {
        super(props);
        this.state = {
            fetching: true,
            mobileNavVisible: true,
        }
    }

    componentDidMount() {
        this.unsubscribeDoc = this.pageItemDoc.onSnapshot(this.onDocUpdate);
    }

    componentWillUnmount() {
        this.unsubscribeDoc();
    }

    renderMobileNav() {
        if (!this.state.mobileNavVisible) {
            this.setState({ mobileNavVisible: true });
        } else {
            this.setState({ mobileNavVisible: false });
        }
    }

    onDocUpdate = (snapshot) => {
        let pageDoc = {};
        snapshot.forEach(function (doc) {
            pageDoc = {
                id: doc.id,
                ...doc.data(),
            };
        });
        this.setState({ fetching: false, ...pageDoc });
    };

    render() {
        let { contentSections = [{ menuItems: [] }] } = this.state
        let menuItems = contentSections[0].menuItems;

        const visibility = this.state.mobileNavVisible ? {} : { 'display': 'flex' };

        return (
            <header id="header" className="default-secondary-bg-color">
                <div className="container">
                    <div className="row inset">
                        <div className="col-2">
                            <Link to="/" className="nav-item" title="Go back to Homepage">
                                <img src={Logo} alt="Telearuba" height="60" />
                            </Link>
                        </div>
                        <div className="col-10">
                            {
                                menuItems && menuItems.length > 0 &&
                                <nav id="main-nav" className="pull-right">
                                    <div id="mobile-nav" onClick={this.renderMobileNav.bind(this)}>
                                        <i className="fas fa-bars primary-color"></i>
                                    </div>
                                    <ul className="nav" style={visibility}>
                                        {
                                            menuItems.map(({ post, menuItemTitle }, i) =>
                                                <li key={i} className="nav-item">
                                                    <Link to={post.permalink} className="nav-item" title={`Go to ${menuItemTitle}`} onClick={this.renderMobileNav.bind(this)}>{menuItemTitle}</Link>
                                                </li>
                                            )
                                        }
                                        <li key={`pay-per-view-menu-nav-item`} className="nav-item">
                                            <Link to={`/ppv`} className="nav-item" title={`Go to Pay Per View`} onClick={this.renderMobileNav.bind(this)}>{`Pay Per View`}</Link>
                                        </li>
                                    </ul>
                                </nav>
                            }
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;
