import React, { Component } from 'react';
import { Link } from 'react-router-dom';
// import GoogleAd from '../subComponents/GoogleAd';

export default class NotFoundPage extends Component {
    render() {
        const {config} = this.props;
        return (
            <main id="main" style={{ minHeight: '50vh' }}>
                <div className="container triple-inset-top">
                    {/* <div className="row">
                        <div className="col-12">
                            <GoogleAd config={config}/>
                        </div>
                    </div> */}
                    <div className="row align-items-center" style={{ height: '40vh' }}>
                        <div className="col-md-12 txt-align-center">
                            <h1 className="primary-color">404 Page not found</h1>
                            <div className="description">
                                <p>The page you are looking for doesn't exist.</p>
                            </div>
                            <Link to="/" className="btn btn-primary">Go to homepage</Link>
                        </div>
                    </div>
                    {/* <div className="row">
                        <div className="col-12">
                            <GoogleAd config={config}/>
                        </div>
                    </div> */}
                </div>
            </main>
        )
    }
}