import React, { Component } from 'react';
import { BrowserView, MobileView, } from "react-device-detect";
import { DEFAULT_SERVER_URL } from '../../Server';

const Text = ({ text = "" }) => <p>{text.split(/[\n\r]/).map((p, i) => <React.Fragment key={`${i}-text-block`}>{p}<br /></React.Fragment>)}</p>

const Blockquote = ({ text, quotee }) =>
    <blockquote className="blockquote">
        <p>{`"${text}" - ${quotee}`}</p>
    </blockquote>

const Image = ({ media, mediaServerURL=DEFAULT_SERVER_URL}) => (
    <img className="content-image img-fluid" src={mediaServerURL + media.largeThumbnail} alt={media.alt} />
);

const Images = ({ images, mediaServerURL=DEFAULT_SERVER_URL}) =>
    <div className="row">
        {images.map((image, i) =>
            <div key={i} className="col-6">
                <Image media={image.media} mediaServerURL={mediaServerURL} />
            </div>
        )}
    </div>

const Video = ({ media, mediaServerURL=DEFAULT_SERVER_URL }) =>
    <div className="row">
        <div className="col-12">
            <div className="embed-responsive embed-responsive-16by9">
                <BrowserView>
                    {media !== undefined &&
                        <video width="730" controls poster={`${mediaServerURL}${media.largeThumbnail}`}>
                            <source src={`${mediaServerURL}${media.originalFile}`} />
                        </video>
                    }
                </BrowserView>
                <MobileView>
                    {media !== undefined &&
                        <video width="400" controls poster={`${mediaServerURL}${media.largeThumbnail}`}>
                            <source src={`${mediaServerURL}${media.stream}`} type="application/x-mpegURL" />
                        </video>
                    }
                </MobileView>
            </div>
        </div>
    </div>

export default class PostContentSections extends Component {
    render() {
        let { contentSections, config} = this.props;
        const mediaServerURL = config.mediaServerURL() || DEFAULT_SERVER_URL;

        return (
            <div className="row">
                <div className="col-12">
                    {
                        (contentSections && contentSections.length > 0) &&
                        contentSections.map(({ type, ...props }, i) => {
                            if (type === "text") return <Text key={i} id={i} {...props} />
                            if (type === "blockquote") return <Blockquote key={i} id={i} {...props} />
                            if (type === "imagearray") return <Images key={i} id={i} {...props} mediaServerURL={mediaServerURL} />
                            if (type === "image") return <Image key={i} id={i} {...props} mediaServerURL={mediaServerURL} />
                            if (type === "video") return <Video key={i} id={i} {...props} mediaServerURL={mediaServerURL} />
                            return null;
                        })
                    }
                </div>
            </div>
        )
    }
}