import React, { Component } from 'react';

class StickyFooterMobileDownload extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showMobileDownloadVisible: true,
        }
    }

    showMobileContainer = () => {
        if(!this.state.showMobileDownloadVisible) {
            this.setState({showMobileDownloadVisible: true});
        } else {
            this.setState({showMobileDownloadVisible: false});
        }
    }
    
    render() {
        const visibility = this.state.showMobileDownloadVisible ? {'bottom':'-69px'} : {'bottom':'0'};

        return (
            <div id="fixed-mobile-download" className="fixed-bottom txt-align-center" style={visibility}>
                <div id="download-mobile" className="default-secondary-bg-color" onClick={this.showMobileContainer.bind(this)}>
                    <i className="fas fa-mobile tertiary-color"></i>
                </div>
                <div id="download-mobile-icons" className="default-secondary-bg-color">
                    <a href="https://play.google.com/store/apps/details?id=aw.setar.telearuba" target="_blank" rel="noopener noreferrer">
                        <img src="/images/google_play.png" alt="Download Telearuba App on Google Play" />
                    </a>
                    <a href="https://itunes.apple.com/us/app/telearuba/id1047738607" target="_blank" rel="noopener noreferrer">
                        <img src="/images/app_store.png" alt="Download Telearuba App on App Store" />
                    </a>
                </div>
            </div>
        );
    }
}

export default StickyFooterMobileDownload;
