import React, { Component } from 'react';
// import AdSection from '../subComponents/AdSection';

class Schedulepage extends Component {
  render() {
    const { topAd, topAdMedia, config, isGoogleAd = false } = this.props;
    return (
      <main id="main">
        {/* <AdSection ad={topAd} preloadedAdMedia={topAdMedia} isGoogleAd={isGoogleAd} config={config} /> */}
        Schedule
      </main>
    );
  }
}

export default Schedulepage;
