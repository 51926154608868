
import React, { Component } from 'react';
import { DEFAULT_SERVER_URL } from '../../Server';

export default class ContentWithSidebar extends Component {
    render() {
        let { contentBlocks, sidebar, config } = this.props;
        if (!contentBlocks || !sidebar) return null;
        let { largeThumbnail, alt } = sidebar.media;

        const mediaServerURL = config.mediaServerURL() || DEFAULT_SERVER_URL;

        return (
            <div className="container triple-inset">
                <div className="row">
                    <div className="col-4">
                        <div className="sticky-top sticky-offset">
                            <img className="content-image img-fluid" src={mediaServerURL + largeThumbnail} alt={alt} />
                        </div>
                    </div>
                    <div className="col-8">
                        {contentBlocks.map(contentBlock => <p>{contentBlock}</p>)}
                    </div>
                </div>
            </div>
        )
    }
}